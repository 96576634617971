import {
  clone,
  defined,
  tryit,
  isArray,
  isFunction,
  isObject,
  isString
} from "@bsgp/lib-core";

function getParentComponent(oComp, className) {
  const maxRetry = 5;
  let retryCount = 0;
  let oDialog = oComp;

  while (retryCount < maxRetry) {
    if (oDialog.getMetadata().getName() === className) {
      return oDialog;
    }
    retryCount += 1;
    try {
      oDialog = oDialog.getParent();
    } catch (ex) {
      return null;
    }
    if (oDialog === null) {
      return null;
    }
  }
}

const getParentDialog = oComp => {
  return getParentComponent(oComp, "sap.m.Dialog");
};

const getParentTable = oComp => {
  return getParentComponent(oComp, "sap.m.Table");
};

const getParentItem = oComp => {
  const oTable = getParentTable(oComp);
  if (oTable) {
    const itemClassName = oTable.mBindingInfos.items.template
      .getMetadata()
      .getName();
    return getParentComponent(oComp, itemClassName);
  }
  return null;
};

export const getDataFromSPath = (data, path) => {
  const convertedPath = path.split("/").filter(path => path !== "");
  let res = convertedPath.reduce(
    (pre, cur) => (isNaN(parseInt(cur)) ? pre[`${cur}`] : pre[cur]),
    data
  );

  if (res.$sub$) {
    res = structuredClone(res);
    delete res.$sub$;
  }

  return res;
};

const getEventValueInReact = oEvent => {
  if (oEvent.componentCtg) {
    // react 버전 CodeEditor이거나 NodeEditor일 경우
    return oEvent.value;
  }

  // react 버전 event handler인 경우
  const target = oEvent.currentTarget || oEvent.target;
  if (!target) return oEvent.value;

  switch (target.tagName) {
    case "UI5-MENU":
      return oEvent.detail.text;
    case "UI5-CHECKBOX":
      return target.checked;
    case "UI5-DATERANGE-PICKER":
      const startDateValue = target.startDateValue;
      const endDateValue = target.endDateValue;
      const startDate = new Date(startDateValue);
      const endDate = new Date(endDateValue);
      endDate.setHours(23, 59, 59, 999);

      return [startDate, endDate];
    case "UI5-FILE-UPLOADER":
      const files = oEvent.detail.files;
      if (files.length === 1) {
        return files[0];
      } else {
        return files;
      }
    case "UI5-MULTI-COMBOBOX":
      return oEvent.detail.items.map(item => item.text);
    default:
      return target.value;
  }
};

const getEventIndexInReact = oEvent => {
  if (!!oEvent.rowData) {
    return oEvent.rowData.index;
  }

  const target = oEvent.currentTarget || oEvent.target;
  if (!target) {
    return undefined;
  }

  if (target.tagName === "UI5-MENU") {
    return oEvent.detail.item.dataset.idx;
  }

  return target.dataset?.idx;
};

const getRowInReact = oEvent => {
  if (!!oEvent.rowData) {
    return oEvent.rowData;
  }

  const target = oEvent.currentTarget || oEvent.target;
  if (!target) {
    return undefined;
  }

  return target.dataset?.row ? JSON.parse(target.dataset.row) : undefined;
};

// react 버전 event handler인 경우
const getValueIfReact = oEvent => {
  if ("selectedIndices" in oEvent) {
    // react 버전 테이블 select일 경우
    return {
      index: oEvent.selectedIndices,
      row: oEvent.selectedRows,
      selected: oEvent.selectedRows.length > 0
    };
  }
  if (["oEvent", "columns", "tableData"].every(key => key in oEvent)) {
    // react 버전 테이블 컴포넌트일 경우
    return oEvent;
  }

  return {
    ...oEvent,
    value: getEventValueInReact(oEvent),
    index: getEventIndexInReact(oEvent),
    row: getRowInReact(oEvent),
    hiddenData: (oEvent.currentTarget || oEvent.target)?.hiddenData,
    selected: (oEvent.currentTarget || oEvent.target)?.selected
  };
};

const getValue = oEvent => {
  if (oEvent.renderAsReact) {
    return getValueIfReact(oEvent);
  }
  let newEvent;
  try {
    newEvent = oEvent.oEvent || oEvent;
    newEvent.getSource();
  } catch (ex) {
    console.log("oEvent:", oEvent);
    throw ex;
  }
  const oComp = newEvent.getSource();
  const className = oComp.getMetadata().getName();
  const params = newEvent.getParameters();

  let hiddenData = undefined;
  const oHiddenData = tryit(() => oComp.getCustomData(), []).find(data => {
    return data.getKey() === "hiddenData";
  });
  if (oHiddenData) {
    hiddenData = oHiddenData.getValue();
  }

  let value = undefined;
  let selected = undefined;
  // console.log("className:", className);
  switch (className) {
    case "sap.m.MultiInput": {
      const eventId = newEvent.getId();
      const oldValue = oComp.getTokens().map(oToken => oToken.getKey());

      value = [...oldValue];
      if (eventId === "submit") {
        if (!value.includes(params.value)) {
          value.push(params.value);
        }
      } else {
        value = value.filter(
          key =>
            params.removedTokens.length === 0 ||
            !params.removedTokens.map(token => token.getKey()).includes(key)
        );
        value.push(...params.addedTokens.map(token => token.getKey()));
      }
      break;
    }
    case "sap.m.MultiComboBox": {
      const oItems = oComp.getSelectedItems();
      value = oItems.map(oItem => oItem.getKey());
      selected = params.selected;
      break;
    }
    case "sap.ui.unified.FileUploader":
      const multipleAllowed = oComp.getMultiple();

      if (multipleAllowed) {
        value = params.files;
      } else {
        value = params.files[0];
      }

      break;
    case "sap.m.ComboBox": {
      const oItem = oComp.getSelectedItem();
      if (oItem) {
        value = oItem.getKey();
      } else {
        value = params.value;
      }
      selected = params.selected;
      break;
    }
    // function addAltDT(field, value, index, replacement) {
    //   if (value[index]) {
    //     const newValue = value[index].
    // replace(new RegExp(replacement, "g"), "");
    //     value[index] = newValue;
    //   }
    // }
    // case "sap.m.DateTimePicker": {
    //   value = params.value.split(" ");
    //   addAltDT(value, 0, "-");
    //   addAltDT(value, 1, ":");
    //   break;
    // }
    // case "sap.m.DatePicker": {
    // if (!params.value) {
    //   value = "00000000";
    // } else {
    //   value = params.value.split("-").join("");
    // }
    //   break;
    // }
    // case "sap.m.TimePicker": {
    //   value = params.value.split(":").join("");
    //   break;
    // }
    case "sap.m.Image":
      value = oComp.getSrc();
      break;
    case "sap.m.Link":
      value = oComp.getText();
      break;
    case "sap.m.DateRangeSelection": {
      // DateTime(params.from).changeFormat(format.A1Date),
      // DateTime(params.to).changeFormat(format.A1Date)
      value = [params.from, params.to];
      break;
    }
    case "sap.m.SegmentedButton":
      value = params.item.getKey();
      break;
    case "sap.m.Select":
      value = params.selectedItem.getKey();
      break;
    case "sap.m.CheckBox":
      value = params.selected;
      break;
    case "sap.ui.core.dnd.DragDropInfo": {
      const { draggedControl, droppedControl, dropPosition } = params;
      const oData = draggedControl.getBindingContext().oModel.oData;
      const fromData = getDataFromSPath(
        oData,
        draggedControl.getBindingContext().sPath
      );
      const toData = getDataFromSPath(
        oData,
        droppedControl.getBindingContext().sPath
      );
      value = { fromData: fromData, toData: toData, position: dropPosition };
      break;
    }
    case "sap.m.RadioButtonGroup": {
      const selectedButton = oComp.getSelectedButton();
      const oCustomData = selectedButton.getCustomData().find(data => {
        return data.getKey() === "key";
      });
      if (oCustomData) {
        value = oCustomData.getValue();
      } else {
        value = undefined;
      }
      break;
    }
    case "sap.m.Switch": {
      value = params.state;
      break;
    }
    /*
      아래 코드가 코멘트 처리된 이유는 getValue() 함수의 목적이 
      oEvent로부터 원천 컴포넌트의 새로운 값을 취득하기 위함인데
      아래 코드는 TreeTable에서 버튼을 클릭했을때 버튼이 위치한 행(row)을
      취득하기 위해 사용했지만 getValue의 목적에도 안 맞고 
      본 Switch구문이 끝난후 row값을 취득하는 로직이 있으므로 중복되고 불필요한 로직이다.
      // case "sap.m.Button": {
      //   const path = oComp.getBindingContext().sPath;
      //   const oData = oComp.getBindingContext().oModel.oData;
      //   value = getDataFromSPath(oData, path);
      //   break;
      // }
    */
    default:
      value = params.value;
      break;
  }

  const oTable = getParentTable(oComp);
  const oTreeTable = oComp.getBindingContext();
  let index, row;

  if (oTable) {
    // console.log("oTable:", oTable);
    const oItem = getParentItem(oComp);
    index = oTable
      .getItems()
      .filter(oItem => oItem.getCells)
      .findIndex(item => oItem === item);
    if (index < 0) {
      index = undefined;
    } else {
      row = oItem.getBindingContext().getObject();
    }
  } else if (oTreeTable) {
    const { oModel, sPath } = oTreeTable;
    row = getDataFromSPath(oModel.oData, sPath);
  }

  return { value, index, row, hiddenData, selected };
};

function updateProperty(draft, name, value, index) {
  const properties = name.split(".");
  let acc = draft;
  properties.forEach((prop, idx) => {
    let newProp = prop;
    if (/\[\]$/.test(prop)) {
      newProp = prop.replace(/\[\]$/, "");
      if (acc[newProp].length === index) {
        const newObj = {};
        acc[newProp].push(newObj);
        acc = newObj;
      } else {
        acc = acc[newProp][index];
      }
    } else {
      if (idx === properties.length - 1) {
        acc[newProp] = value;
      } else {
        acc = acc[newProp];
      }
    }
  });
  return draft;
}

function confirmWithDialog(msg, onOk) {
  window.sap.ui.require(["sap/m/MessageBox"], function(MessageBox) {
    MessageBox.confirm(msg, {
      actions: [MessageBox.Action.OK, MessageBox.Action.CANCEL],
      emphasizedAction: MessageBox.Action.OK,
      onClose: function(sAction) {
        if (onOk) {
          if (sAction === MessageBox.Action.OK) {
            onOk();
          }
        }
      }
    });
  });
}

function getChildIdMapping(listData, childName) {
  return listData.reduce((acc, el, idx) => {
    if (acc[el[childName]] === undefined) {
      acc[el[childName]] = [];
    }
    acc[el[childName]].push(idx);
    return acc;
  }, {});
}

function list_to_tree(listData, options = {}) {
  // refs https://dev.to/nas5w/building-deep-trees-in-javascript
  // -using-object-references-4565
  const { parentName = "parentId", childName = "id" } = options;
  const roots = [];

  const childIdMapping = getChildIdMapping(listData, childName);

  listData.forEach(el => {
    // Handle the root element
    const parentValue = isArray(el[parentName])
      ? el[parentName].find(paId => childIdMapping[paId] !== undefined)
      : el[parentName];

    if (parentValue === undefined || parentValue === "") {
      roots.push(el);
      return;
    }
    // 상위 ParentName이 지정되어 있으나 해당 el가 없는 경우 에러 방지
    if (childIdMapping[parentValue] === undefined) {
      roots.push(el);
      return;
    }
    // Use our mapping to locate the parent element in our listData array
    childIdMapping[parentValue].forEach(idx => {
      const parentEl = listData[idx];
      // Add our current el to its parent's `children` array
      el._parent = parentValue;
      parentEl.$sub$ = [...(parentEl.$sub$ || []), el];
    });
  });

  // if (isObject(roots)) {
  //   return { $sub$: [roots] };
  // }
  return { $sub$: roots };
}

function convertToTreeData(arrayData = [], options = {}) {
  const { parentName, childName } = options;

  const result = list_to_tree(clone(arrayData), {
    parentName,
    childName
  });
  return result;
}

const extractArgs = args => {
  const key = isString(args[0])
    ? args[0]
    : isFunction(args[0])
    ? args[0]()
    : undefined;
  const options =
    isArray(args[0]) || isObject(args[0])
      ? args[0]
      : isArray(args[1]) || isObject(args[1])
      ? args[1]
      : {};
  return [key, options];
};

function removeUndefinedKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

function refineProps(props, fn) {
  const properties = defined(
    tryit(() => props),
    {}
  );

  return removeUndefinedKeys(
    Object.keys(properties).reduce((acc, key) => {
      acc[key] = properties[key];
      if (isFunction(properties[key])) {
        acc[key] = properties[key](fn);
      }
      return acc;
    }, {})
  );
}

function isBound(value) {
  return isString(value) ? /^\{{1}(= ){0,1}.{1,}\}$/.test(value) : false;
}

export {
  getChildIdMapping,
  getParentDialog,
  getParentTable,
  getParentItem,
  getValue,
  removeUndefinedKeys,
  refineProps,
  extractArgs,
  isBound,
  confirmWithDialog,
  convertToTreeData,
  updateProperty
};
