import { factory, byId } from "ui5-lib-rc";
import { tryit, defined } from "@bsgp/lib-core";
import { isTruthy, isArray, isFunction } from "@bsgp/lib-core";
import path from "path";
import {
  constructFormData,
  constructTableData,
  constructFooterData,
  constructToolbar,
  getField,
  buildForms,
  buildTables,
  fieldComponent,
  buildCodeEditor,
  refineEventForDialog
} from "./common";
import { makeid } from "@bsgp/lib-core";
import SparkMD5 from "spark-md5";

let lastHash;
let lastLang;
let lastLocaleState;
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function createComponent(
  state,
  props,
  fn,
  { pageKey, onResize, getProps }
) {
  const compFactory = factory(pageKey);
  const {
    form,
    formContainer,
    formElement,
    vBox,
    hBox,
    component,
    column,
    text,
    button,
    columnListItem,
    link,
    dialog,
    customData
  } = compFactory;
  const getFieldWithFactory = getField(compFactory, fn);

  const { title, data, getUI5, history, currentUser } = getProps();
  const { center = false, hCenter = false, vCenter = false } = data;

  const stringData = JSON.stringify(
    { data, currentUser },
    getCircularReplacer()
  );
  const thisHash = SparkMD5.hash(stringData);
  console.log("%cft data:", "color:red", data);
  // console.log("data:", stringData);

  const containerPage = byId("containerPage");
  const wasFromFt = customData.value("isFromFt", containerPage);
  if (wasFromFt === "1") {
    if (thisHash === lastHash && !data.nodeeditor && !getUI5) {
      if (
        lastLang === currentUser.lang &&
        lastLocaleState === currentUser.localeState
      ) {
        console.log("data is same");
        return {
          ignore: true,
          isFromFt: "1"
        };
      }
    }
  }
  lastHash = thisHash;
  lastLang = currentUser.lang;
  lastLocaleState = currentUser.localeState;

  if (isFunction(getUI5)) {
    getUI5(compFactory, fn, fieldComponent);
  }

  const wrapForms = tryit(() => data.wrapForms);
  const wrapSingleTable = tryit(() => data.wrapSingleTable);
  const formData = constructFormData(data.form, fn);
  const hasFooter = isTruthy(data.footer);

  const forms = buildForms(
    form,
    formContainer,
    formElement,
    hBox,
    component,
    customData,
    onResize
  )(formData, { pageKey, getFieldWithFactory, wrapForms, hasFooter });

  const tableData = constructTableData(data.table, fn, pageKey);

  const tables = buildTables(
    column,
    text,
    columnListItem,
    component,
    hBox,
    vBox,
    customData
  )(tableData, {
    pageKey: ["root", pageKey].join("_"),
    getFieldWithFactory,
    wrapSingleTable
  });

  let iFrameNode;
  if (data.nodeeditor) {
    const nodeeditorID = "nodeeditor_" + new Date().getTime() + makeid(5);
    const neToolbar = constructToolbar(data.nodeeditor.toolbar, "nodeeditor");
    iFrameNode = vBox.set({
      name: "editorWrapper",
      items: [
        isTruthy(neToolbar) &&
          component.set(window.sap.m.OverflowToolbar, {
            name: `nodeeditor_toolbar`,
            settings: {
              design: window.sap.m.ToolbarDesign.Transparent,
              ...neToolbar.properties,
              content: neToolbar.content.map(bar => {
                return getFieldWithFactory(bar.name, bar);
              })
            },
            callback: (comp, isInitial) => {
              if (isInitial) {
                comp.addStyleClass("sapUiSizeCompact");
              }
            }
          }),
        component.set(window.sap.ui.core.HTML, {
          name: "nodeeditor",
          content: `
      <div id="${nodeeditorID}" 
      class="nodeeditor" style="width:100vw; height:60vh;">
      </div>
    `,
          callback: (comp, isInitial) => {
            // console.log("editor callback:", nodeeditorID);
            let maxRetry = 0;
            const onLoadEditor = setInterval(() => {
              const elEditor = window.document.querySelector(
                `#${nodeeditorID}`
              );
              if (elEditor && elEditor.parentElement) {
                console.log("callback:1");
                data.nodeeditor.onLoadEditor(fn)(
                  elEditor,
                  data.nodeeditor.enabled
                );
                clearInterval(onLoadEditor);
              }
              // console.log("callback:2");
              maxRetry += 1;
              if (maxRetry === 10) {
                clearInterval(onLoadEditor);
              }
            }, 200);
          }
        })
      ].filter(Boolean)
    });
  }

  if (data.dialog) {
    if (!data.dialog.content) {
      data.dialog.content = {};
    }
    const cePageKey = `${pageKey}_dialog`;
    const content = Object.keys(data.dialog.content).reduce((acc, key) => {
      switch (key) {
        case "form": {
          const formDataDialog = constructFormData(
            data.dialog.content.form,
            fn
          );

          const formsDialog = buildForms(
            form,
            formContainer,
            formElement,
            hBox,
            component,
            customData,
            onResize
          )(formDataDialog, {
            pageKey: cePageKey,
            getFieldWithFactory
          });

          return acc.concat(formsDialog);
        }
        case "table": {
          if (data.dialog.content.table) {
            Object.keys(data.dialog.content.table).forEach(tableKey => {
              data.dialog.content.table[
                tableKey
              ].component.hideDownloadButton = true;
              data.dialog.content.table[tableKey].component.compactSize = true;
            });
          }

          const tableData = constructTableData(
            data.dialog.content.table,
            fn,
            pageKey
          );

          const tables = buildTables(
            column,
            text,
            columnListItem,
            component,
            hBox,
            vBox,
            customData
          )(tableData, {
            pageKey: cePageKey,
            getFieldWithFactory
          });
          return acc.concat(tables);
        }
        case "codeeditor": {
          const ceInstances = buildCodeEditor(
            { codeeditor: data.dialog.content.codeeditor },
            fn,
            {
              customData,
              component,
              button,
              pageKey: cePageKey,
              getFieldWithFactory
            }
          );
          acc.push(...ceInstances);
          break;
        }
        default: {
          break;
        }
      }
      return acc;
    }, []);

    const buttons = Object.keys(defined(data.dialog.buttons, {})).map(key => ({
      name: `${cePageKey}_${key}_dialog`,
      field: {
        value: data.dialog.buttons[key].value,
        component: {
          type: "Button",
          properties: data.dialog.buttons[key].properties
        }
      }
    }));

    const beginButton =
      buttons[0] && getFieldWithFactory(buttons[0].name, buttons[0].field);
    const endButton =
      buttons[1] && getFieldWithFactory(buttons[1].name, buttons[1].field);

    component.set(window.sap.m.Dialog, {
      name: cePageKey,
      content: vBox.set({
        name: [cePageKey, "ceWrapper"].join("_"),
        height: "100%",
        items: content
      }),
      stretch: true,
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("dialog-h-100");
        }
        if (data.dialog.isOpen) {
          if (data.dialog.content.codeeditor) {
            component
              .get([cePageKey, "codeeditor"].join("_"))
              .setValue(data.dialog.content.codeeditor.value);
          }
          comp.open();
        } else {
          comp.isOpen() && comp.close();
        }
      },
      ...(beginButton && { beginButton }),
      ...(endButton && { endButton }),
      ...data.dialog.properties
    });
  }

  if (data.dialogV2) {
    Object.keys(data.dialogV2).forEach(dialogKey => {
      const dialogData = data.dialogV2[dialogKey];
      const {
        properties: dlProperties,
        ftData: dlFtData,
        isOpen: isOpenV2,
        fullSize: fullSizeV2,
        title: dlTitle,
        disableEsc,
        beginButtonText,
        hideBeginButton,
        endButtonText,
        onClose,
        onConfirm,
        onCancel
      } = dialogData;
      const formDataDialog = constructFormData(dlFtData.form, fn);

      const dialogId = `${dialogKey}_dlgV2`;

      const formsDialog = buildForms(
        form,
        formContainer,
        formElement,
        hBox,
        component,
        customData,
        onResize
      )(formDataDialog, {
        pageKey: `${pageKey}_${dialogId}`,
        getFieldWithFactory
      });

      if (dlFtData.table) {
        Object.keys(dlFtData.table).forEach(tableKey => {
          dlFtData.table[tableKey].component.hideDownloadButton = true;
          dlFtData.table[tableKey].component.compactSize = true;
        });
      }

      const tableData = constructTableData(dlFtData.table, fn, pageKey);

      const tables = buildTables(
        column,
        text,
        columnListItem,
        component,
        hBox,
        vBox,
        customData
      )(tableData, {
        pageKey: ["dialog", pageKey, dialogKey].join("_"),
        getFieldWithFactory
      });

      const ceArray = [];
      if (dlFtData.codeeditor) {
        const ceInstances = buildCodeEditor(dlFtData.codeeditor, fn, {
          customData,
          component,
          button,
          pageKey: dialogId,
          getFieldWithFactory
        });
        ceArray.push(...ceInstances);
      }

      //   const beginButton =
      //   buttons[0] && getFieldWithFactory(buttons[0].name, buttons[0].field);
      const beginButtonOnPress = () => oEvent => {
        const toCloseDialog = oEvent.toCloseDialog;

        const oHeader = oEvent.getSource().getParent();
        const oDialog = oHeader.getParent();

        const oCustomData = oDialog.getCustomData().find(data => {
          return data.getKey() === "origin";
        });
        if (oCustomData) {
          oCustomData.setValue("confirm");
        } else {
          oDialog.addCustomData(
            customData.set({
              name: [dialogId, "origin"].join("_"),
              key: "origin",
              value: "confirm"
            })
          );
        }

        // let result;
        // if (onConfirm) {
        //   result = onConfirm(fn)(oEvent);
        //   if (isPromise(result)) {
        //     result = await result;
        //   }
        // }

        if (toCloseDialog !== false) {
          oDialog.close();
        }
      };
      const beginButtonName = [dialogId, "begin"].join("-");
      const beginButtonData = {
        name: beginButtonName,
        field: {
          value: beginButtonText || "확인",
          component: {
            type: "Button",
            properties: {
              type: window.sap.m.ButtonType.Emphasized,
              ...(button.get(beginButtonName)
                ? {}
                : {
                    press: beginButtonOnPress
                  })
            }
          }
        }
      };
      const beginButton = hideBeginButton
        ? false
        : getFieldWithFactory(beginButtonData.name, beginButtonData.field);
      const endButtonData = {
        name: [dialogId, "end"].join("-"),
        field: {
          value: endButtonText || "닫기",
          component: {
            type: "Button",
            properties: {
              type: window.sap.m.ButtonType.Reject,
              press: () => oEvent => {
                const toCloseDialog = oEvent.toCloseDialog;

                const oHeader = oEvent.getSource().getParent();
                const oDialog = oHeader.getParent();

                const oCustomData = oDialog.getCustomData().find(data => {
                  return data.getKey() === "origin";
                });
                if (oCustomData) {
                  oCustomData.setValue("close");
                } else {
                  oDialog.addCustomData(
                    customData.set({
                      name: [dialogId, "origin"].join("_"),
                      key: "origin",
                      value: "close"
                    })
                  );
                }

                // let result;
                // if (onCancel) {
                //   result = onCancel(fn)(oEvent);
                //   if (isPromise(result)) {
                //     result = await result;
                //   }
                // }

                if (toCloseDialog !== false) {
                  oDialog.close();
                }
              }
            }
          }
        }
      };
      const endButton = getFieldWithFactory(
        endButtonData.name,
        endButtonData.field
      );

      const titleText = dlTitle || dlProperties.title;
      const dialogTitle =
        titleText &&
        getFieldWithFactory([dialogId, "title"].join("-"), {
          value: titleText
        });

      const dialogButtons = [beginButton, endButton, dialogTitle].filter(
        Boolean
      );

      dialog.set({
        name: dialogId,
        content: vBox.set({
          name: [dialogId, "ceWrapper"].join("_"),
          height: "100%",
          items: formsDialog.concat(tables).concat(ceArray)
        }),
        escapeHandler:
          disableEsc === true
            ? promise => {
                // escape key 를 눌렀을때 dialog가 close되지 않게 함.
                promise.reject("");
                // promise.resolve("");
              }
            : undefined,
        stretch: defined(fullSizeV2, true),
        customHeader: component.set(window.sap.m.OverflowToolbar, {
          name: [dialogId, "header"].join("-"),
          content: dialogButtons,
          callback: comp => {
            comp.addStyleClass("sapUiSizeCompact");
          }
        }),
        ...(onClose && { afterClose: onClose(fn) }),
        ...dlProperties,
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("dialog-h-100");
          }

          if (isOpenV2 === true) {
            comp.isOpen() || comp.open();
          } else if (isOpenV2 === false) {
            comp.isOpen() && comp.close();
          }
        }
      });

      if (onConfirm || onCancel) {
        // Input.valueHelpRequest 에서 사용하는 Dialog 일 경우
        //  refineEventForDialog()가 여기서 실행되면 안됨.
        //  따라서 addDialog()할때 직접 onConfirm()이나 onCancel()를 받았을때만
        //  valueHelpRequest를 사용하지 않는다고 간주하고
        //  refineEventForDialog()를 실행함.
        dialogButtons.forEach(each =>
          refineEventForDialog(each, {
            onConfirm: onConfirm && onConfirm(fn),
            onBeforeCancel: onCancel && onCancel(fn)
          })
        );
      }
    });
  }

  let oCode;
  if (data.codeeditor) {
    const [oToolbar, oEditor] = buildCodeEditor(
      data.codeeditor.hasOwnProperty("value") ||
        data.codeeditor.hasOwnProperty("properties")
        ? { codeeditor: data.codeeditor }
        : data.codeeditor,
      fn,
      {
        customData,
        component,
        button,
        pageKey,
        getFieldWithFactory
      }
    );
    if (oToolbar || oEditor) {
      oCode = vBox.set({
        name: "ceWrapper",
        height: "100%",
        items: [oToolbar, oEditor].filter(Boolean)
      });
    }
  }

  const footerData = constructFooterData(data.footer);

  const btnToggleForm =
    forms.length === 0 || tables.length === 0
      ? []
      : [
          {
            name: "toggle_forms_separator",
            field: {
              value: "",
              component: {
                type: "Separator"
              }
            }
          },
          {
            name: "toggle_forms",
            field: {
              value: "",
              component: {
                type: "Button",
                properties: {
                  icon: "sap-icon://collapse-group",
                  press: () => oEvent => {
                    const oThis = oEvent.getSource();
                    if (oThis.getIcon() === "sap-icon://collapse-group") {
                      oThis.setIcon("sap-icon://expand-group");
                      oThis.addStyleClass("ft-notify-form-is-hidden");
                    } else {
                      oThis.setIcon("sap-icon://collapse-group");
                      oThis.removeStyleClass("ft-notify-form-is-hidden");
                    }

                    defined(forms, []).forEach((oComp, idx) => {
                      if (wrapForms === true && idx > 0) {
                        return;
                      }
                      if (oComp) {
                        oComp.toggleStyleClass("ft-hide-with-effect");
                        if (
                          oComp.getMetadata().getName() === "sap.m.IconTabBar"
                        ) {
                          // oComp.setExpanded(!oComp.getExpanded());
                        } else {
                        }
                      }
                    });
                  }
                }
              }
            }
          }
        ];

  const footerComponents = footerData.concat(btnToggleForm).map(each => {
    return getFieldWithFactory(each.name, each.field);
  });

  const roofComponents = [];

  const historyLinks = isArray(data.historyLinks) ? data.historyLinks : [];
  if (isTruthy(historyLinks)) {
    const lastIndex = historyLinks.length - 1;
    const presentLink = history.location.pathname;
    roofComponents.push(
      component.set(window.sap.m.Breadcrumbs, {
        name: "history_links",
        currentLocationText: title,
        links: historyLinks
          .filter((each, idx) => {
            let href = each.url || each.href || "";
            href = path.resolve(presentLink, href);

            return !(
              lastIndex === idx &&
              presentLink.toLowerCase() === href.toLowerCase()
            );
          })
          .map((hLink, idx) => {
            let href = hLink.url || hLink.href || "";
            if (href) {
              href = path.resolve(presentLink, href);
              href += history.location.search;
            }

            return link.set({
              name: ["hLink-", idx].join(""),
              text: hLink.text,
              // href
              press: () => {
                history.push(href);
              }
            });
          }),
        callback: comp => {
          comp.addStyleClass("sapUiSmallMarginBegin sapUiTinyMarginTop");
        }
      })
    );
  }

  if (hasFooter) {
    roofComponents.push(
      component.set(window.sap.m.OverflowToolbar, {
        name: "toolHeader",
        altPrefix: "",
        settings: {
          design: window.sap.m.ToolbarDesign.Transparent,
          content: footerComponents
        },
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("sapUiSizeCompact");
          }
          if (data.stickyFooter) {
            comp.addStyleClass("ft-sticky");
          } else {
            comp.removeStyleClass("ft-sticky");
          }
        }
      })
    );
  }

  const wrapper = vBox.set({
    name: "wrapper",
    ...(hCenter !== false && {
      alignItems: window.sap.m.FlexAlignItems.Center
    }),
    ...(center === true ||
      (vCenter === true && {
        justifyContent: window.sap.m.FlexJustifyContent.Center
      })),
    fitContainer: true,
    backgroundDesign: window.sap.m.BackgroundDesign.Solid,
    items: [
      vBox.set({
        name: "innerWrapper",
        fitContainer: false,
        height: "100%",
        backgroundDesign: window.sap.m.BackgroundDesign.Solid,
        items: []
          .concat(forms)
          .concat(tables)
          .concat(oCode)
          .filter(Boolean),
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("max-height-100p");
          }
        }
      }),
      iFrameNode
    ].filter(Boolean),
    callback: (comp, isInitial) => {
      // if (isInitial) {
      if (oCode) {
        comp.removeStyleClass("ft-height-fit-content");
        comp.addStyleClass("ft-height-fill-available");
      } else {
        comp.removeStyleClass("ft-height-fill-available");
        comp.addStyleClass("ft-height-fit-content");
      }
      // }
    }
  });

  const page = {
    isFromFt: "1",
    title,
    content: roofComponents.concat([wrapper]),
    floatingFooter: false,
    customFooterContent: []
  };
  return page;
}
